import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SAEFeatureViewer = () => {
  const [features, setFeatures] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFeatures = async () => {
      try {
        const context = require.context(
          "../hack/spam_messages_roberta_tied_weights",
          false,
          /feature_.*\.json$/
        );
        const featureFiles = context.keys();
        const loadedFeatures = await Promise.all(
          featureFiles.map(async (key) => {
            const feature = await import(
              `../hack/spam_messages_roberta_tied_weights/${key.slice(2)}`
            );
            return feature.default;
          })
        );
        setFeatures(loadedFeatures.sort((a, b) => b.confidence - a.confidence));
      } catch (error) {
        console.error("Error loading features:", error);
      } finally {
        setLoading(false);
      }
    };

    loadFeatures();
  }, []);

  const filteredFeatures = features.filter(
    (feature) =>
      feature.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
      feature.attributes.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderSamples = (samples, title) => (
    <Box mt={2}>
      <Typography variant="h6" color="primary" gutterBottom>
        {title}
      </Typography>
      <List dense>
        {samples.map((sample, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={sample.text}
              secondary={`Activation: ${sample.act.toFixed(4)}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        SAE Feature Viewer
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        label="Search features..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 4 }}
      />

      {loading ? (
        <Typography>Loading features...</Typography>
      ) : (
        <Grid container spacing={3}>
          {filteredFeatures.map((feature) => (
            <Grid item xs={12} key={feature.index}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    <Chip label={`Feature ${feature.index}`} color="primary" />
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                      {feature.label}
                    </Typography>
                    <Chip
                      label={`Confidence: ${feature.confidence.toFixed(1)}`}
                      color={feature.confidence > 15 ? "success" : "default"}
                      size="small"
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        gutterBottom
                      >
                        Attributes
                      </Typography>
                      <Typography paragraph>{feature.attributes}</Typography>

                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        gutterBottom
                      >
                        Reasoning
                      </Typography>
                      <Typography paragraph>{feature.reasoning}</Typography>

                      <Box sx={{ display: "flex", gap: 4, mb: 2 }}>
                        <Typography>
                          <strong>Density:</strong>{" "}
                          {(-Math.log10(feature.density)).toFixed(6)}
                        </Typography>
                        <Typography>
                          <strong>Confidence:</strong> {feature.confidence}
                        </Typography>
                      </Box>

                      <Divider />

                      {renderSamples(
                        feature.high_act_samples,
                        "High Activation Samples"
                      )}
                      {renderSamples(
                        feature.low_act_samples,
                        "Low Activation Samples"
                      )}
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default SAEFeatureViewer;
